import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetUsers, dispatchUpdateUser } from '@/store/admin/actions';
import { readAdminOneUser } from '@/store/admin/getters';
let EditUser = class EditUser extends Vue {
    constructor() {
        super(...arguments);
        this.valid = true;
        this.fullName = '';
        this.email = '';
        this.isActive = true;
        this.isSuperuser = false;
        this.setPassword = false;
        this.password1 = '';
        this.password2 = '';
    }
    async mounted() {
        await dispatchGetUsers(this.$store);
        this.reset();
    }
    reset() {
        this.setPassword = false;
        this.password1 = '';
        this.password2 = '';
        this.$validator.reset();
        if (this.user) {
            this.fullName = this.user.full_name;
            this.email = this.user.email;
            this.isActive = this.user.is_active;
            this.isSuperuser = this.user.is_superuser;
        }
    }
    cancel() {
        this.$router.back();
    }
    async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile = {};
            if (this.fullName) {
                updatedProfile.full_name = this.fullName;
            }
            if (this.email) {
                updatedProfile.email = this.email;
            }
            updatedProfile.is_active = this.isActive;
            updatedProfile.is_superuser = this.isSuperuser;
            if (this.setPassword) {
                updatedProfile.password = this.password1;
            }
            await dispatchUpdateUser(this.$store, { id: this.user.id, user: updatedProfile });
            this.$router.push('/main/admin/users');
        }
    }
    get user() {
        return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
    }
};
EditUser = __decorate([
    Component
], EditUser);
export default EditUser;
